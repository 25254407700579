/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

h1,
h2,
h3,
h4,
h5,
h6,
ion-title,
ion-card-title,
ion-item,
ion-button,
ion-label,
ion-input {
  font-family: "DM Sans", sans-serif;
}

ion-card {
  border-radius: 10px;
  box-shadow: none;
}

ion-button {
  height: 2.65rem;
  font-size: 0.9rem;
  --border-radius: 0.35rem;
  --padding-start: 0.75rem;
  --padding-end: 0.75rem;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
}

ion-split-pane {
  top: var(--ion-header-height);
}

ion-header[collapse="condense"] {
  // position: sticky;
  // top: 0;
}

ion-content {
  @media only screen and (max-width: 768px) {
    // --padding-top: var(--ion-header-height);
  }
}

ion-searchbar {
  padding-inline: 0 !important;
}

ion-tabs {
  ion-tab-bar {
    transition: height 0.2s ease-in-out;
    --background: #00000034;
    --border: none;

    ion-tab-button[layout="icon-start"] {
      ion-label {
        margin-left: 10px;
      }
    }
  }
}

.container {
  &.max {
    padding: 0 calc((100% - var(--ion-max-container-width)) / 2) !important;
  }

  &.mid {
    padding: 0 calc((100% - var(--ion-mid-container-width)) / 2) !important;
  }

  &.min {
    padding: 0 calc((100% - var(--ion-min-container-width)) / 2) !important;
  }
}

.bottom-block {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #0000003b;
  backdrop-filter: blur(20px) saturate(1.5);
  -webkit-backdrop-filter: blur(20px) saturate(1.5);

  .edit-buttons-container {
    // border-top-left-radius: 10px;

    padding-block: 16px;
    padding-inline: 16px;
  }
}

.edit-buttons-container__mobile {
  position: absolute;
  bottom: 0;
  background: #0000007d;
  width: 100%;
  backdrop-filter: blur(10px) saturate(1.5);
  -webkit-backdrop-filter: blur(10px) saturate(1.5);

  .edit-buttons-slider__mobile {
    padding: 25px;
    .swiper-pagination-bullet {
      background: white;
    }
    .icon-buttons-slide {
      display: flex;
      justify-content: space-evenly;
    }
  }
}

.mobile-edit-button {
  padding-inline: 40px;
}
.swiper-button-next {
  color: white;
  --swiper-navigation-size: 30px;
}
.swiper-button-prev {
  color: white;
  --swiper-navigation-size: 30px;
}
.swiper-pagination-bullet-active {
  background: white;
}
.swiper-pagination {
  bottom: 3px !important;
}
.scenes-sections-accordion {
  background: var(--ion-card-background);
  border-radius: 8px;
  ion-item {
    border-radius: 8px;
  }
  .scenes-sections-item-name {
    margin-left: 15px !important;
  }
  &.accordion-expanded {
    background: var(--ion-background-color);
  }
}

.page-content {
  &.edit-page {
    --padding-bottom: 150px;
  }
}
.edit-header {
  margin-top: var(--ion-header-height);
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.filled {
  &.material-symbols-outlined {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }
}

.align-center {
  display: flex;

  &.vertical {
    align-items: center;
  }

  &.horizontal {
    justify-content: center;
  }
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  // .sc-ion-modal-ios-h {
  //   --height: 800px;
  //   --width: 1400px;
  //   --border-radius: 0;
  // }
  // .form-modal {
  //   .sc-ion-modal-ios-h {
  //     --width: 600px;
  //   }
  // }

  // .fullpage-modal {
  //   .sc-ion-modal-ios-h {
  //     --width: 100%;
  //     --height: 100%;
  //   }
  // }
  ion-modal {
    &.fullpage {
      --width: 100%;
      --height: 100%;
    }

    &.wide {
      --width: 90%;
      --height: 80%;
    }
  }
}

.hotspot-point {
  transition: all 0.3s ease-in-out;

  &:not(.scene-link) &:not(.section-link) {
    background: transparent;
    // box-shadow: 0px 3px 25px -5px rgba(0, 0, 0, 0.75);
    // border-radius: 50%;
    // padding: 5px;
    // border: 2px solid;
    // border-color: rgba(255, 255, 255, 0.75);
  }

  &.scene-link {
    background-image: url(assets/icons/placeholder.svg);
    width: 72px;
    height: 87px;
    background-position: 0px 0px;
    background-size: 72px 87px;

    .hotspot-point__img {
      transition: all 0.3s ease-in-out;
      width: 48px;
      height: 48px;
      background-position: 0px 0px !important;
      background-size: cover !important;
      border-radius: 50%;
      margin: 13px 0px 0px 12px;
    }

    &:hover {
      width: 79px;
      height: 96px;
      background-size: 79px 96px;
      //box-shadow: 0px 3px 25px -5px rgba(0, 0, 0, 0.75);

      .hotspot-point__img {
        width: 53px;
        height: 53px;
        background-position: 0px 0px !important;
        background-size: cover !important;
        border-radius: 50%;
        margin: 14px 0px 0px 13px;
      }

      .scene-link__name {
        width: 53px;
        height: 53px;
        margin: 14px 0px 0px 13px;
        //padding: 16px 1px ;
        font-size: 11.15px;
      }
    }
  }

  .scene-link__name {
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 13px 0px 0px 12px;
    padding: 15px 1px;
    font-size: 10px;
    text-align: center;
    background: #00000045;
  }

  &.section-link,
  &.sections-menu,
  &.info-panel {
    ion-button {
      color: white;
      --border-radius: 0;
      --border-width: 3px;
      box-shadow: 0 0 8px #00000057;

      &:hover {
        //color: var(--ion-color-primary);
        --border-radius: 0;
        // --border-color: white;
        background: var(--ion-color-primary);

        ion-icon {
          //color: var(--ion-color-primary);
        }
      }
    }
  }

  .hotspot__media-container {
    display: none;

    .hotspot__media-close {
      position: absolute;
      right: -50px;
      top: -50px;
    }

    .hotspot__video {
    }

    .hotspot__photo {
      max-width: 250px;
    }

    &.opened {
      display: block;
    }
  }

  .hotspot__icon {
    padding: 5px;
    border: 2px solid;
    border-radius: 50%;
    font-size: x-large;
    transition: font-size 0.3s ease-in-out;
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.75);

    &.hidden {
      display: none;
    }
  }

  .explanation {
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    //width: -webkit-fill-available;
    font-size: 15px;
    opacity: 0;
    width: 200px;
    left: -81px;
    text-align: center;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.75);
  }

  &:hover {
    &:not(.written-infospot) {
      ion-icon {
        //font-size: xx-large;
      }
    }
    border-color: rgba(255, 255, 255, 1);

    ion-icon {
      color: rgba(255, 255, 255, 1);
    }

    .explanation {
      opacity: 1;
    }
  }
}
